<script setup>
import SvgIcon from './SvgIcon.vue';
import { storeToRefs } from 'pinia';
import { useCommonStore } from '../stores/common.store';
import { useAuthStore } from '../stores/auth.store';
import { useCollectionStore } from '../stores/collection.store';
import { useIdle, useTimeoutPoll } from '@vueuse/core';
import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { get } from 'lodash';
import { useSearch } from '../composables/search';
import { useStorage } from '@vueuse/core';

const userStore = useAuthStore();
const { user } = storeToRefs(userStore);
// const { search } = storeToRefs(useCollectionStore());
const { search } = useSearch();
const common = useCommonStore();
const { pageTitle, online } = storeToRefs(common);

const { idle } = useIdle(1000 * 60); // 1 sec
userStore.presense();

watch(idle, (newIdle) => {
  if (!newIdle) {
    userStore.presense();
  }
});

const route = useRoute();
const router = useRouter();

async function getOnlineUsers() {
  await common.getOnlineUsers();
}
getOnlineUsers();

const isShowTestUsers = useStorage('showTestUsers', false);
const handlerChangeIsShowTestUser = () => router.go();
// const { resume } = useTimeoutPoll(getOnlineUsers, 1000); // 1 min

// resume();
</script>

<template>
  <header id="header" class="sticky top-0 z-50 flex h-[72px] shrink-0 items-center border-b border-b-[#F1F1F1] bg-white px-5 md:h-[69px] md:px-10 xl:px-8">
    <RouterLink class="flex h-[69px] items-center xl:hidden" to="/">
      <div>
        <SvgIcon name="logo" class="mb-1.5 h-3 w-20 xl:h-4 xl:w-[106px]" />
      </div>
      <div class="ml-5 flex h-10 w-10 items-center justify-center rounded-full border border-black xl:ml-8">
        <SvgIcon name="home" class="h-4 w-4" />
      </div>
    </RouterLink>
    <h1 v-if="route.name" class="hidden items-center text-desktop-h2 uppercase xl:flex">
      <span class="flex items-center">{{ route.name }} </span>
      <!-- <span v-if="pageSubTitle" class="ml-4 inline-block text-desktop-h3-bold normal-case">{{ pageSubTitle }}</span> -->
    </h1>
    <div v-if="route.name === 'Designs'" class="relative text-gray-600 pl-4">
      <input type="search" v-model="search" name="serch" placeholder="Search" class="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none border" />
      <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
        <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background: new 0 0 56.966 56.966" xml:space="preserve" width="512px" height="512px">
          <path
            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
          />
        </svg>
      </button>
    </div>
    <div class="ml-auto flex items-center">
      <div id="showTestUserSettings" class="mr-4">
        <label class="relative flex justify-between items-center group text-md">
          Show test users?
          <input v-model="isShowTestUsers" type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" @click="handlerChangeIsShowTestUser"/>
          <span
            class="w-9 h-6 flex items-center flex-shrink-0 ml-4 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-slate-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-0.5"
          ></span>
        </label>
      </div>
      <div id="online-users" title="Online users from production">
        <div class="flex items-center pr-3">
          <div class="flex items-center text-slate-400">
            <span class="relative flex h-2 w-2">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
            </span>
            <span class="ml-2 text-[12px]">{{ online.users }}</span>
          </div>
        </div>
      </div>
      <RouterLink id="userHeader" class="logoBorder flex items-center pr-6 hover:underline" to="/profile" data-cy="account-logo">
        <div class="ml-3 max-w-[200px] overflow-hidden pr-5 text-[11px] font-black leading-[145%]">
          <div>{{ user?.user_metadata?.email }}</div>
        </div>
        <div class="flex h-10 w-10 items-center overflow-hidden rounded-full border border-black bg-[#D9D9D9]">
          <img :src="user?.user_metadata?.avatar_url" referrerpolicy="no-referrer" />
        </div>
      </RouterLink>
      <RouterLink to="/logout" class="flex h-10 cursor-pointer items-center justify-center overflow-hidden rounded-full text-left text-[12px] font-black uppercase leading-none hover:underline"><logout-icon class="text-slate-200 duration-300 hover:text-black" /></RouterLink>
    </div>
  </header>
</template>

<style>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
